//Featured slide

// $(".featured-slider").owlCarousel({

//   loop:true,
//   animateOut: 'fadeOut',
//   autoplay:false,
//   autoplayHoverPause:true,
//   nav:true,
//   margin:0,
//   dots:false,
//   mouseDrag:true,
//   touchDrag:true,
//   slideSpeed:500,
//   navText: ["<i class='fa fa-angle-left'></i>", "<i class='fa fa-angle-right'></i>"],
//   items : 1,
//   responsive:{
//     0:{
//       items:1
//     },
//     600:{
//       items:1
//     }
//   }

// });

//Latest news slide

$(".latest-news-slide").owlCarousel({

  loop:false,
  animateIn: 'fadeInLeft',
  autoplay:false,
  autoplayHoverPause:true,
  nav:true,
  margin:30,
  dots:false,
  mouseDrag:false,
  slideSpeed:500,
  navText: ["<i class='fa fa-angle-left'></i>", "<i class='fa fa-angle-right'></i>"],
  items : 3,
  responsive:{
    0:{
      items:1
    },
    600:{
      items:3
    }
  }

});

//Latest news slide