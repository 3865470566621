window.fetchBanners = function(page) {
  $.ajax({
    dataType: "json",
    url: '/api/v1/banners?page=' + page,
    success: (data) => injectBanners(data)
  });
}

window.sendClick = function(advId) {
  $.ajax({
    type: 'POST',
    dataType: "json",
    url: '/api/v1/banners/' + advId + '/click',
    success: (data) => console.log('Click tracked')
  });
}

window.trackClick = function(ev) {
  sendClick($(this).data('banner_id'));
}

window.closeFullPageAdv = function(ev) {
  jQuery('#fullpage_adv').fadeOut('fast').remove()
}

window.displayAdvCloseBtn = function() {
  // console.log('Enabled close button')
  jQuery('#fullpage_adv .full-page__close').addClass('full-page__close--enabled')
}

window.injectBanners = function(data) {
  const banners = data?.banners ?? { banners: {}}

  Object.keys(banners).forEach(key => {
    if ($(`.adv-box.adv-box--${key}`).length) {
      // console.log(`Checking for key ${key} - selector: .adv-box.adv-box--${key}`)
      const banner = banners[key]
      $(`.adv-box.adv-box--${key} .adv-image--desktop`).attr('src', banner.desktop)
      $(`.adv-box.adv-box--${key} .adv-image--desktop`).removeAttr('data-src')
      $(`.adv-box.adv-box--${key} .adv-image--mobile`).attr('src', banner.mobile)
      $(`.adv-box.adv-box--${key} .adv-image--mobile`).removeAttr('data-src')
      $(`.adv-box.adv-box--${key}`).addClass('adv-box--loaded')
      $(`.adv-box.adv-box--${key} a.adv__link`).attr('href', banner.destination_link)
      $(`.adv-box.adv-box--${key} a.adv__link`).attr('target', '_blank')
      jQuery(`.adv-box.adv-box--${key} a.adv__link`).data('banner_id', banner.id)
    }
  });
  jQuery('.adv-box--loaded a.adv__link').on('click', trackClick)
  if (jQuery('#fullpage_adv').length == 1) {
    console.log('Detected fullpage adv')
    jQuery('#fullpage_adv .full-page__close').on('click', closeFullPageAdv)
    if (jQuery('#fullpage_adv .adv-box--loaded').length > 0)
      jQuery('#fullpage_adv').addClass('fullpage-adv--loaded')
    setTimeout(displayAdvCloseBtn, 4000)
  }

}

window.showDefaults = function () {
  $('.adv-box').attr('style', 'display: block !important');
  const images = [].slice.call(document.getElementsByClassName('adv-image'));
  images.forEach(image => {
    if (typeof $(image).data('src') != 'undefined') {
      console.log(`Replacing image ${$(image).data('src')}`)
      $(image).attr('src', $(image).data('src'))
    }
  })
}

jQuery(function() {

  if (document.location.search.indexOf('adv_test') > -1) {
    showDefaults()
  } else {
    const pageType = document.getElementsByTagName('body')[0].classList[document.getElementsByTagName('body')[0].classList.length -1]
    fetchBanners(pageType)
  }

});
