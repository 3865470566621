const { appUtils } = require('./app-utils.js');

window.appUtils = appUtils

if (document.querySelectorAll('.js-flash').length) {
  document.addEventListener("DOMContentLoaded", function() {
    document.querySelectorAll('.js-flash').forEach(el => {
      console.log(`Showing flash ${el.dataset.messageType}: ${el.dataset.messageText}...`)
      appUtils.toast(el.dataset.messageType, el.dataset.messageText, el.dataset.messageTitle)
    })
    console.log("JS ready");
  });
}